
import firebase from "firebase/app";
import "firebase/storage";
import { Options, Vue } from "vue-class-component";
import { saveErrorLog } from "@/api/error";
import { postFile, deleteFile } from "@/api/storage";
import store from "@/store";

@Options({
  emits: ["input"],
  props: {
    label: String,
    name: String,
    text: String,
    images: Array
  }
})
export default class MImageFileInput extends Vue {
  label = "";
  name = "";
  text = "";
  images: string[] = [];
  uploading = false;

  get dynamicCss() {
    const css: { [name: string]: boolean } = {};
    if (this.uploading) {
      css["bg-gray-300"] = true;
      css["text-white"] = true;
      css["cursor-default"] = true;
    } else {
      css["cursor-pointer"] = true;
      css["text-primary-500"] = true;
      css["bg-white"] = true;
      css["hover:bg-gray-200"] = true;
    }
    return css;
  }

  async upload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.student
    ) {
      return;
    }

    this.uploading = true;

    const file = event.target.files[0];
    try {
      const { downloadURL } = await postFile(file, {
        path: `images/students/${store.state.student.ref.id}/${Date.now()}`
      });
      this.$emit("input", downloadURL);
    } catch (e) {
      alert(`画像のアップロードに失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to upload image"
      );
    }

    event.target.value = "";
    this.uploading = false;
  }

  async deleteImage(url: string) {
    try {
      const imageRef = firebase.storage().refFromURL(url);
      await deleteFile(imageRef);
      // this.images を上書きすると "TypeError: 'set' on proxy" エラーが発生してしまうので splice を使用
      const index = this.images.indexOf(url);
      this.images.splice(index, 1);
    } catch (e) {
      alert(`画像の削除に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete reflection image"
      );
    }
  }
}
