<template>
  <div class="w-full">
    <label for="goal" class="leading-7 text-sm font-medium text-gray-500">
      学習時間の比率
    </label>
    <div class="text-sm">
      <div
        v-for="subject in subjects"
        :key="subject.name"
        class="flex items-center my-2"
      >
        <div class="mr-3 text-gray-400 w-12">{{ subject.name }}</div>
        <input
          type="number"
          :disabled="subjects.length === 1"
          :name="`ratio_${subject.name}`"
          placeholder="1"
          :value="subject.ratio"
          class="flex-grow bg-white border border-white h-11 py-1 rounded-md focus:border-primary-500 text-base outline-none appearance-none py-1 px-3 leading-8 transition-border duration-300"
          :class="{ 'text-gray-300': subjects.length === 1 }"
          @blur="
            e =>
              update({
                name: subject.name,
                ratio: +e.target.value
              })
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { LearningSubject } from "@/entities/learning";

@Options({
  emits: ["update"],
  props: {
    subjects: Array
  }
})
export default class MSubjectsInput extends Vue {
  subjects: LearningSubject[] = [];
  update(subject: LearningSubject) {
    this.$emit("update", subject);
  }
}
</script>
