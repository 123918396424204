<template>
  <div class="flex flex-col w-full">
    <div class="mb-4">
      <span class="leading-7 text-sm font-medium text-gray-500">{{
        label
      }}</span>
      <label
        :for="name"
        class="flex py-2 px-3 rounded-md transition-all duration-300"
        :class="dynamicCss"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="w-6 h-6 mr-2"
          viewBox="0 0 24 24"
        >
          <path
            v-show="!uploading"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          />
          <path
            v-show="uploading"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        <span v-show="!uploading">{{ text }}</span>
        <span v-show="uploading">アップロード中</span>
        <input
          :id="name"
          type="file"
          accept="image/*;capture=camera"
          :name="name"
          :disabled="uploading"
          class="hidden"
          @change="upload"
        />
      </label>
    </div>

    <div v-if="images.length > 0" class="flex w-full overflow-x-scroll">
      <template v-for="image in images" :key="image">
        <div class="relative mr-4 flex-shrink-0">
          <img :src="image" alt="成果画像" class="h-40 rounded-md" />
          <button
            type="button"
            class="absolute top-0 right-0 rounded-full w-10 h-10 bg-white bg-opacity-70 flex justify-center items-center"
            @click="deleteImage(image)"
          >
            <svg
              class="h-8 w-8 text-black"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import firebase from "firebase/app";
import "firebase/storage";
import { Options, Vue } from "vue-class-component";
import { saveErrorLog } from "@/api/error";
import { postFile, deleteFile } from "@/api/storage";
import store from "@/store";

@Options({
  emits: ["input"],
  props: {
    label: String,
    name: String,
    text: String,
    images: Array
  }
})
export default class MImageFileInput extends Vue {
  label = "";
  name = "";
  text = "";
  images: string[] = [];
  uploading = false;

  get dynamicCss() {
    const css: { [name: string]: boolean } = {};
    if (this.uploading) {
      css["bg-gray-300"] = true;
      css["text-white"] = true;
      css["cursor-default"] = true;
    } else {
      css["cursor-pointer"] = true;
      css["text-primary-500"] = true;
      css["bg-white"] = true;
      css["hover:bg-gray-200"] = true;
    }
    return css;
  }

  async upload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.student
    ) {
      return;
    }

    this.uploading = true;

    const file = event.target.files[0];
    try {
      const { downloadURL } = await postFile(file, {
        path: `images/students/${store.state.student.ref.id}/${Date.now()}`
      });
      this.$emit("input", downloadURL);
    } catch (e) {
      alert(`画像のアップロードに失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to upload image"
      );
    }

    event.target.value = "";
    this.uploading = false;
  }

  async deleteImage(url: string) {
    try {
      const imageRef = firebase.storage().refFromURL(url);
      await deleteFile(imageRef);
      // this.images を上書きすると "TypeError: 'set' on proxy" エラーが発生してしまうので splice を使用
      const index = this.images.indexOf(url);
      this.images.splice(index, 1);
    } catch (e) {
      alert(`画像の削除に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to delete reflection image"
      );
    }
  }
}
</script>
