
import { Options, Vue } from "vue-class-component";
import MCommentPanel from "@/components/MCommentPanel.vue";
import MSubjectsInput from "@/components/MSubjectsInput.vue";
import MSubjectsRatioInput from "@/components/MSubjectsRatioInput.vue";
import MButton from "@/components/form/MButton.vue";
import MImageFileInput from "@/components/form/MImageFileInput.vue";
import MInfoField from "@/components/form/MInfoField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import store, { awaitStudentLoaded } from "@/store";
import {
  Learning,
  learningCollectionKey,
  LearningReflection,
  LearningSubject
} from "@/entities/learning";
import { getLearning, setReflectionToLearning } from "@/api/learning";
import { StudentGrade } from "@/entities/student";
import { saveErrorLog } from "@/api/error";
import dayjs from "dayjs";

type ReflectionSelectOption = {
  value: number;
  text: string;
};

@Options({
  components: {
    MCommentPanel,
    MSubjectsInput,
    MSubjectsRatioInput,
    MButton,
    MImageFileInput,
    MInfoField,
    MTextArea,
    MTextField,
    MSelectBox
  }
})
export default class ReflectionForm extends Vue {
  learning: Learning | null = null;
  achievementRate = "";
  enteredAchievement = false;
  subjects: LearningSubject[] = [];
  additionalContent = "";
  images: string[] = [];
  concentration = 5;
  concentrations: ReflectionSelectOption[] = [
    {
      value: 5,
      text: "5. とても集中できた"
    },
    {
      value: 4,
      text: "4. まあまあ集中できた"
    },
    {
      value: 3,
      text: "3. どちらともいえない"
    },
    {
      value: 2,
      text: "2. あまり集中できなかった"
    },
    {
      value: 1,
      text: "1. 全く集中できなかった"
    }
  ];
  complaint = 5;
  complaints: ReflectionSelectOption[] = [
    {
      value: 5,
      text: "5. 困ったことやトラブルは、全く無かった"
    },
    {
      value: 4,
      text: "4. 困ったことやトラブルは、ほとんど無かった"
    },
    {
      value: 3,
      text: "3. 困ったことやトラブルがあった"
    },
    {
      value: 2,
      text: "2. 困ったことやトラブルが結構あった"
    },
    {
      value: 1,
      text: "1. 困ったことやトラブルがたくさんあった"
    }
  ];
  thoughts = "";
  other = "";
  timeMessages = {
    morning: "朝からいいスタートが切れたね🌅",
    daytime: "がんばったね✍️学習室オープンしたら、そっちでも待っているね🚪",
    evening: "いつも応援しているよ📣",
    midnight: "夜遅くまでがんばったね🌃体調には気を付けてね🛌"
  };
  followingMessages = [
    "この学習はきっと力になっていると思うよ🐸",
    "一歩一歩進んでいくことがとても大事🏃‍♂️",
    "次の学習も、一緒に頑張っていこうね🌈",
    "今やったことを振り返って、さらに学習の「質」を高めていこう⤴️",
    "このがんばりが、自信につながっていくはずだよ💚",
    "継続は力なり！ぜひ次も一緒に勉強しよう📝",
    "続けていくことが大切だから、ぜひ次回の学習予約もしちゃおう📆",
    "ちょっとずつでも、必ず前に進んでいるから、自分を信じて進んでいこう🎲",
    "あなたのがんばりは、いつか花開くときがくるよ🌸",
    "少しずつでも続けていくことが大きな前進に実はなっているものだよ🍑"
  ];
  defaultCheeringMessage =
    "がんばったね！振り返りをして、これからの学習につなげよう！";
  cheeringMessage = "";

  get goal(): string {
    return this.learning?.data.goal ?? "";
  }

  get studentGrade(): StudentGrade {
    return store.state.student?.data.grade ?? "その他";
  }

  get validAchivementRate(): boolean {
    return /^(\d{1,2}|100)$/.test(this.achievementRate);
  }

  get validConcentration(): boolean {
    return this.concentration > 0 && this.concentration <= 5;
  }

  get validComplaint(): boolean {
    return this.complaint > 0 && this.complaint <= 5;
  }

  get validSubjects(): boolean {
    return this.subjects.length > 0;
  }

  get validSubjectsRatio(): boolean {
    return this.subjects.every(({ ratio }) => ratio >= 1);
  }

  get validForm() {
    return (
      this.validAchivementRate &&
      this.validConcentration &&
      this.validComplaint &&
      this.validSubjects &&
      this.validSubjectsRatio
    );
  }

  addImagePath(url: string) {
    this.images.push(url);
  }

  changeSubjects(subjects: LearningSubject[]) {
    this.subjects = [...subjects];
  }

  updateSubjectRatio(subject: LearningSubject) {
    this.subjects = this.subjects.map(s => {
      if (s.name !== subject.name) return s;
      s.ratio = Math.round(subject.ratio);
      return s;
    });
  }

  allEnter() {
    this.enteredAchievement = true;
  }

  async send() {
    this.allEnter();

    if (!this.validForm || !this.learning) {
      return;
    }

    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "振り返り登録中...");
    const reflection: LearningReflection = {
      achievementRate: Number(this.achievementRate),
      additionalContent: this.additionalContent,
      images: this.images,
      concentration: this.concentration,
      complaint: this.complaint,
      thoughts: this.thoughts,
      other: this.other
    };

    try {
      await setReflectionToLearning(
        this.learning.ref,
        reflection,
        this.subjects
      );
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      alert(
        `振り返りの登録に失敗しました。\n時間を置いてから再度実行してみてください。\n\n${e}`
      );
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to register reflection to learning"
      );
      return;
    }
    store.commit("SET_LOADING", false);
    store.commit("SET_LOAD_TEXT", "");

    this.$router.replace({
      path: "/room/reservation",
      query: {
        reflection: "true",
        learning: this.learning.ref.id
      }
    });
  }

  async created() {
    const student = await awaitStudentLoaded(store);
    const learningId = this.$route.params.learningId as string;
    if (!learningId) {
      return;
    }

    try {
      const learning = await getLearning(
        student.ref.collection(learningCollectionKey).doc(learningId)
      );
      if (!learning) {
        alert("学習データが見つかりませんでした");
        this.$router.replace("/");
        return;
      }
      this.learning = learning;
      if (this.learning.data.reflection) {
        /**
         通知から遷移した場合など、記入済の場合は保存された値をセットする
         */
        this.achievementRate =
          `${this.learning.data.reflection.achievementRate}` ||
          this.achievementRate;
        this.additionalContent =
          this.learning.data.reflection.additionalContent ||
          this.additionalContent;
        this.concentration =
          this.learning.data.reflection.concentration || this.concentration;
        this.complaint =
          this.learning.data.reflection.complaint || this.complaint;
        this.other = this.learning.data.reflection.other || this.other;
        this.thoughts = this.learning.data.reflection.thoughts || this.thoughts;
        this.learning.data.reflection.images.forEach(image =>
          this.images.push(image)
        );
      }
      this.subjects = learning.data.subjects;
      if (!this.learning.data.meta || this.learning.data.meta.type === "room") {
        // 学習室での学習履歴の場合、応援メッセージは固定
        this.cheeringMessage = this.defaultCheeringMessage;
      } else {
        // タイムキーパーでの学習履歴の場合、時間帯に応じた応援メッセージを表示する
        let timeRange: "morning" | "daytime" | "evening" | "midnight";
        const currentHour = dayjs().hour();
        const currentMinutes = dayjs().minute();
        if (currentHour >= 5 && currentHour < 11) {
          timeRange = "morning";
        } else if (currentHour >= 11 && currentHour < 18) {
          timeRange = "daytime";
        } else if (
          (currentHour >= 18 && currentHour < 22) ||
          (currentHour === 22 && currentMinutes < 30)
        ) {
          timeRange = "evening";
        } else {
          timeRange = "midnight";
        }
        this.cheeringMessage = this.timeMessages[timeRange];
        this.cheeringMessage +=
          this.followingMessages[
            Math.floor(Math.random() * this.followingMessages.length)
          ];
      }
    } catch (e) {
      alert(`学習データの取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to get learning data"
      );
    }
  }
}
